@charset "UTF-8";
/* COLORS */
:root {
  --background_color: #f7f7f7;
  --header_color: #143109;
  --alt_background: #efefef;
  --fun: #ae7f7f;
  --bad: #cd963e;
  --worse: #cd6b3e;
  --worst: #cd3e3e;
  --good: #7acd3e;
  --better: #86ce27;
  --best: #0fdf24;
  --link_color: #aebd93;
  --following_background: #ecf4e4;
  --invert_text: #143109;
  --invert_background: #dcdfd6;
  --selected_link: #7a8450;
  --text_color: #000;
}
body {
  background-color: var(--background_color);
}
.footerbody {
  background-color: var(--alt_background);
}
.mobilenav {
  background-color: var(--alt_background);
  border-color: var(--header_color);
}
.followingb {
  background-color: transparent;
}
.goodzone {
  color: var(--good);
}
.betterzone {
  color: var(--better);
}
.bestzone {
  color: var(--best);
}
.badzone {
  color: var(--bad);
}
.worsezone {
  color: var(--worse);
}
.worstzone {
  color: var(--worst);
}
.customlogin {
  background-color: transparent;
}
h1,
h2,
h3 {
  color: var(--header_color);
}
p,
pre {
  color: var(--text_color);
}
p::selection {
  background: var(--alt_background);
}
a {
  color: var(--link_color);
}
a:hover {
  color: var(--selected_link);
}
u {
  color: var(--header_color);
}
button {
  background-color: var(--link_color);
  color: var(--invert_text);
}
button:hover {
  background-color: var(--selected_link);
}
form {
  color: var(--invert_text);
}
input {
  background-color: var(--alt_background);
  color: var(--invert_text);
}
input:focus::placeholder {
  color: transparent;
}
.header {
  background-color: var(--background_color);
}
::-webkit-scrollbar-track {
  background-color: var(--header_color);
}
::-webkit-scrollbar-thumb {
  background: var(--alt_background);
}
.quotesection {
  background-color: var(--alt_background);
  border-color: var(--header_color);
}
.archivediv,
.stockdiv,
.logindiv {
  background-color: var(--alt_background);
}
.followingdiv {
  background-color: var(--following_background);
}
.selectedlink {
  color: var(--selected_link);
}
.notselectedlink {
  color: var(--link_color);
}
.interestingbusiness {
  background-color: var(--invert_background);
}
@media only screen and (min-width: 1100px) {
  .rightsection {
    background-color: var(--alt_background);
    border-color: var(--header_color);
  }
}
@media only screen and (min-width: 1200px) {
  .specialrightsection,
  .specialrightsectionforfund {
    background-color: var(--background_color);
    border-color: var(--header_color);
  }
}
/* FONTS */
@font-face {
  font-family: "Pacifico";
  src: local("Pacifico"), url("Pacifico-Regular.ttf");
  font-weight: normal;
}
@font-face {
  font-family: "Quicksand";
  src: local("Quicksand"), url("Quicksand-VariableFont_wght.ttf");
  font-weight: normal;
}
h1,
h2,
h3 {
  font-family: Pacifico, serif;
  font-variant: normal;
  font-weight: 400;
  line-height: 50px;
}
h4 {
  font-family: Quicksand, serif;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: 60px;
  text-decoration: none;
}
h1 {
  font-size: 20px;
}
h2 {
  font-size: 15px;
}
h3 {
  font-size: 14px;
}
p,
a {
  font-family: Quicksand, serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 30px;
  text-decoration: none;
}
a {
  text-decoration: underline;
}
u {
  font-family: Quicksand, serif;
  font-size: 15px;
  font-style: normal;
  font-weight: 600;
  line-height: 48px;
  text-decoration: none;
}
button {
  font-family: Quicksand, serif;
  font-size: 14px;
  font-weight: 600;
  line-height: 30px;
  text-decoration: none;
}
form {
  font-family: Quicksand, serif;
  font-size: 14px;
  font-weight: 600;
  line-height: 30px;
  text-decoration: none;
}
input {
  font-family: Quicksand, serif;
  font-size: 14px;
  font-weight: 600;
  line-height: 30px;
  text-decoration: none;
}
.followingp {
  font-family: Pacifico, serif;
  font-variant: normal;
  font-weight: 400;
  line-height: 30px;
}
.followingb {
  text-decoration: underline;
}
.followingb:hover {
  font-style: italic;
}
@media only screen and (min-width: 700px) {
  h1 {
    font-size: 30px;
    line-height: 80px;
  }
  h2 {
    font-size: 20px;
  }
  h3 {
    font-size: 18px;
  }
  h4 {
    font-size: 20px;
  }
  p,
  a {
    font-size: 17px;
  }
  button {
    font-size: 16px;
  }
  input {
    font-size: 16px;
  }
  u {
    font-size: 18px;
  }
}
@media only screen and (min-width: 1000px) {
  h1 {
    font-size: 40px;
  }
  h2 {
    font-size: 30px;
  }
  h3 {
    font-size: 20px;
  }
}
@media only screen and (min-width: 1100px) {
  h1 {
    font-size: 50px;
  }
  h2 {
    font-size: 40px;
  }
  h3 {
    font-size: 24px;
  }
  h4 {
    font-size: 22px;
  }
}
