@charset "UTF-8";

html {
  margin: 0;
  overscroll-behavior: none;
}

body {
  font-size: 16px;
  margin: 0 auto;
  padding: 0;
  max-width: 900px;
}

h1,
h2,
h3 {
  margin: 30px 0;
  padding: 0;
  text-align: center;
  width: 100%;
}

p,
pre,
h4 {
  margin: 8px 0;
  padding: 0 8%;
  width: 84%;
}

a {
  margin: 0;
  padding: 0;
  transition: 1s;
  width: auto;
}

nav {
  margin: 0;
  padding: 0;
  text-align: center;
  width: 100%;
}

ul {
  list-style: none;
  margin: 0;
  padding: 0;
}

li {
  display: inline-block;
  margin: 0 5px;
  padding: 0;
}

nav a {
  padding: 0 2px;
}

section {
  float: none;
  margin: 0 auto;
  padding: 0;
  width: 100%;
}

hr {
  padding: 3px 0;
  margin: 10px 8%;
  border: none;
  border-top: 5px dashed black;
  width: 84%;
}

form {
  margin: 10px 0;
  padding: 0;
  text-align: left;
  width: 100%;
}

button {
  transition: 1s;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  margin: 10px 0;
  padding: 0 10px;
  width: 100%;
}

input {
  border: none;
  border-radius: 5px;
  cursor: pointer;
  margin: 10px 2%;
  padding: 0;
  text-align: center;
  width: 96%;
  -moz-border-radius: 5px;
  -webkit-border-radius: 5px;
}

input,
select {
  font-size: 100%;
}

footer {
  margin: 0;
  padding: 10px 0;
  -moz-border-radius: 5px;
  -webkit-border-radius: 5px;
}

footer p {
  margin: 0;
  padding: 0;
  width: 100%;
}

img {
  margin: 0;
  max-width: 100%;
  padding: 0;
  vertical-align: middle;
}

p span span {
  font-style: normal;
}

span {
  font-style: italic;
}

u {
  width: 100%;
}

::-webkit-scrollbar {
  width: 10px;
}

::-webkit-scrollbar-track {
  border-radius: 0;
}

::-webkit-scrollbar-thumb {
  border-radius: 0;
}

/* CUSTOM */
.centeredp {
  text-align: center;
}

.stockdiv {
  opacity: 0;
  position: relative;
  margin: 20px;
  padding: 20px 0;
  border-radius: 10px;
}

.archivediv {
  position: relative;
  margin: 20px;
  padding: 20px 0;
  border-radius: 10px;
}

.fade-in {
  opacity: 100;
  transition: opacity 1s ease-in-out;
}

.followingp {
  text-align: center;
  font-style: italic;
}

.followingb {
  width: 30%;
  margin: 0 5% 0 65%;
}

.followingb:hover {
  background-color: transparent;
}

.logindiv {
  margin: 20px 0;
  padding: 20px;
  border-radius: 10px;
}

.interestingbusiness {
  position: relative;
  margin: 10px 0;
  padding: 1px 0 10px 0;
  border-radius: 20px;
  width: 100%;
}

.interestingbusiness .stockname {
  text-align: center;
}

.interestingbusiness .unfollowlink {
  text-align: center;
  margin: 0 30%;
  width: 40%;
}

.horizontalnav {
  text-align: center;
}

.horizontalnav li {
  display: inline-block;
  padding: 0 10px;
}

.breakhere {
  display: block;
}

/* RESPONSIVITY */
@media (hover: hover) {
  .responsiveheader {
    display: block;
  }
}

@media only screen and (min-width: 1000px) {
  body {
    margin: 0;
    padding: 50px 0 0 0;
  }

  h1 {
    text-align: left;
  }

  nav {
    text-align: left;
  }

  li {
    display: block;
    margin: 0;
    padding: 0;
  }

  nav a {
    padding: 0 4px 0 0;
  }

  p,
  h4 {
    padding: 0;
    width: 100%;
  }

  hr {
    margin: 20px 30%;
    width: 40%;
  }

  .breakhere {
    display: none;
  }

  .archivediv,
  .stockdiv,
  .logindiv {
    padding: 20px 20px;
  }

  .accountoptions {
    min-height: 40px;
  }

  .accountoptions .customlogin {
    cursor: default;
  }

  .accountoptions .customaccountbuttons {
    float: left;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    margin: 10px 2.25%;
    padding: 0;
    width: 45%;
  }

  .interestingbusiness .stockname {
    text-align: left;
    margin-left: 50px;
  }
}

@media only screen and (min-width: 1200px) {
  .archivediv button {
    border: none;
    border-radius: 5px;
    cursor: pointer;
    margin: 10px 35%;
    padding: 0 10px;
    width: 30%;
  }

  .stockdiv button {
    border: none;
    border-radius: 5px;
    cursor: pointer;
    margin: 10px 35%;
    padding: 0 10px;
    width: 30%;
  }

  .followingb {
    width: 20% !important;
    margin: 0 5% 0 75% !important;
    position: absolute;
  }
}
