.leftsection {
  position: relative;
  z-index: -2;
}
.rightsection,
.anotherrightsection {
  position: relative;
  z-index: 0;
}
.leftsection img {
  border-radius: 50%;
  margin: 0 5%;
  width: 90%;
  user-select: none;
  z-index: -2;
  -moz-user-select: none;
  -ms-user-select: none;
  -webkit-user-drag: none;
  -webkit-user-select: none;
}
.quotesection {
  width: 80%;
  margin: 20px 10%;
  box-sizing: border-box;
  border-radius: 5px;
  border-style: solid;
}

@media screen and (min-width: 360px) {
  .leftsection {
    float: left;
    position: inherit;
    width: 30%;
  }
  .leftsection img {
    clip-path: ellipse(200px 200px at -18% 150px);
    border-radius: 0;
    margin: 0;
    object-fit: scale-down;
    object-position: -130px;
    position: absolute;
    width: 100%;
  }

  .rightsection {
    float: right;
    padding: 50px 0 0 0;
    position: inherit;
    width: 60%;
  }
  .anotherrightsection {
    float: right;
    padding-top: 20px;
    position: inherit;
    width: 100%;
  }
  .rightsection p {
    text-align: left;
  }
}
@media screen and (min-width: 400px) {
  .leftsection img {
    object-position: -150px;
  }
  .rightsection {
    width: 65%;
  }
  .anotherrightsection {
    padding: 50px 0 0 0;
  }
}
@media screen and (min-width: 500px) {
  .leftsection img {
    clip-path: ellipse(200px 200px at -5% 150px);
    object-position: -170px;
  }
  .rightsection {
    width: 67%;
  }
  .anotherrightsection {
    padding: 120px 0 0 0;
  }
}
@media screen and (min-width: 550px) {
  .anotherrightsection {
    padding: 160px 0 0 0;
  }
}
@media screen and (min-width: 600px) {
  .leftsection img {
    clip-path: ellipse(200px 200px at 0 150px);
    object-position: -200px -100px;
  }
}
@media screen and (min-width: 700px) {
  .rightsection {
    padding: 75px 0 0 0;
  }
  .leftsection img {
    clip-path: ellipse(200px 200px at 5% 160px);
    object-position: -200px -100px;
  }
}
@media screen and (min-width: 800px) {
  .leftsection img {
    clip-path: ellipse(200px 200px at 10% 160px);
    object-position: -260px -100px;
  }
}
@media only screen and (min-width: 900px) {
  .whywrapper {
    display: flex;
    flex-direction: column;
  }
  .leftsection {
    width: 40%;
  }
  .leftsection img {
    border-radius: 50%;
    float: right;
    clip-path: none;
    position: unset;
    object-position: unset;
    width: 90%;
  }
  .rightsection {
    float: right;
    width: 60%;
  }
  .rightsection p {
    padding-left: 20px;
    text-align: left;
  }
  .anotherrightsection {
    padding: 20px 0 0 0;
  }
}
@media only screen and (min-width: 1000px) {
  .leftsection {
    float: none;
    position: relative;
    width: 74%;
  }
  .leftsection img {
    border-radius: 50%;
    float: none;
    clip-path: none;
    margin: 0;
    position: relative;
    object-position: 0;
    width: 100%;
  }
  .rightsection {
    float: none;
    padding: 0;
    position: relative;
    width: 100%;
  }
  .rightsection p {
    padding: 0;
  }
  .anotherrightsection {
    padding: 50px 0 0 0;
  }
}
@media only screen and (min-width: 1100px) {
  .leftsection {
    width: 80%;
  }
  .rightsection {
    padding: 8px;
    position: absolute;
    border-radius: 5px;
    left: 700px;
    top: 670px;
    width: 300px;
  }
  .rightsection {
    border-style: solid;
  }
  .anotherrightsection {
    padding: 60px 0 0 0;
  }
}
@media only screen and (min-width: 1300px) {
  .rightsection {
    left: 900px;
  }
  .anotherrightsection {
    padding: 20px 0 0 0;
  }
}
@media only screen and (min-width: 1400px) {
  .rightsection {
    left: 1000px;
  }
}
