.header {
  margin: 0;
  padding: 0;
  width: 100%;
}

@media only screen and (min-width: 1000px) {
  .header {
    padding: 0;
    position: fixed;
    left: 60px;
    width: 300px;
  }
  nav {
    display: block;
  }
}
@media only screen and (min-width: 1200px) {
  .header {
    padding: 0;
    position: fixed;
    left: 120px;
    width: 300px;
  }
}
@media only screen and (min-width: 1400px) {
  .header {
    padding: 0;
    position: fixed;
    left: 150px;
    width: 300px;
  }
}
