@charset "UTF-8";

html {
  margin: 0;
  overscroll-behavior: none;
}

body {
  font-size: 16px;
  margin: 0 auto;
  padding: 0;
  max-width: 900px;
}

h1,
h2,
h3 {
  margin: 30px 0;
  padding: 0;
  text-align: center;
  width: 100%;
}

p,
pre,
h4 {
  margin: 8px 0;
  padding: 0 8%;
  width: 84%;
}

a {
  margin: 0;
  padding: 0;
  transition: 1s;
  width: auto;
}

nav {
  margin: 0;
  padding: 0;
  text-align: center;
  width: 100%;
}

ul {
  list-style: none;
  margin: 0;
  padding: 0;
}

li {
  display: inline-block;
  margin: 0 5px;
  padding: 0;
}

nav a {
  padding: 0 2px;
}

section {
  float: none;
  margin: 0 auto;
  padding: 0;
  width: 100%;
}

hr {
  padding: 3px 0;
  margin: 10px 8%;
  border: none;
  border-top: 5px dashed black;
  width: 84%;
}

form {
  margin: 10px 0;
  padding: 0;
  text-align: left;
  width: 100%;
}

button {
  transition: 1s;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  margin: 10px 0;
  padding: 0 10px;
  width: 100%;
}

input {
  border: none;
  border-radius: 5px;
  cursor: pointer;
  margin: 10px 2%;
  padding: 0;
  text-align: center;
  width: 96%;
  -moz-border-radius: 5px;
  -webkit-border-radius: 5px;
}

input,
select {
  font-size: 100%;
}

footer {
  margin: 0;
  padding: 10px 0;
  -moz-border-radius: 5px;
  -webkit-border-radius: 5px;
}

footer p {
  margin: 0;
  padding: 0;
  width: 100%;
}

img {
  margin: 0;
  max-width: 100%;
  padding: 0;
  vertical-align: middle;
}

p span span {
  font-style: normal;
}

span {
  font-style: italic;
}

u {
  width: 100%;
}

::-webkit-scrollbar {
  width: 10px;
}

::-webkit-scrollbar-track {
  border-radius: 0;
}

::-webkit-scrollbar-thumb {
  border-radius: 0;
}

/* CUSTOM */
.centeredp {
  text-align: center;
}

.stockdiv {
  opacity: 0;
  position: relative;
  margin: 20px;
  padding: 20px 0;
  border-radius: 10px;
}

.archivediv {
  position: relative;
  margin: 20px;
  padding: 20px 0;
  border-radius: 10px;
}

.fade-in {
  opacity: 100;
  transition: opacity 1s ease-in-out;
}

.followingp {
  text-align: center;
  font-style: italic;
}

.followingb {
  width: 30%;
  margin: 0 5% 0 65%;
}

.followingb:hover {
  background-color: transparent;
}

.logindiv {
  margin: 20px 0;
  padding: 20px;
  border-radius: 10px;
}

.interestingbusiness {
  position: relative;
  margin: 10px 0;
  padding: 1px 0 10px 0;
  border-radius: 20px;
  width: 100%;
}

.interestingbusiness .stockname {
  text-align: center;
}

.interestingbusiness .unfollowlink {
  text-align: center;
  margin: 0 30%;
  width: 40%;
}

.horizontalnav {
  text-align: center;
}

.horizontalnav li {
  display: inline-block;
  padding: 0 10px;
}

.breakhere {
  display: block;
}

/* RESPONSIVITY */
@media (hover: hover) {
  .responsiveheader {
    display: block;
  }
}

@media only screen and (min-width: 1000px) {
  body {
    margin: 0;
    padding: 50px 0 0 0;
  }

  h1 {
    text-align: left;
  }

  nav {
    text-align: left;
  }

  li {
    display: block;
    margin: 0;
    padding: 0;
  }

  nav a {
    padding: 0 4px 0 0;
  }

  p,
  h4 {
    padding: 0;
    width: 100%;
  }

  hr {
    margin: 20px 30%;
    width: 40%;
  }

  .breakhere {
    display: none;
  }

  .archivediv,
  .stockdiv,
  .logindiv {
    padding: 20px 20px;
  }

  .accountoptions {
    min-height: 40px;
  }

  .accountoptions .customlogin {
    cursor: default;
  }

  .accountoptions .customaccountbuttons {
    float: left;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    margin: 10px 2.25%;
    padding: 0;
    width: 45%;
  }

  .interestingbusiness .stockname {
    text-align: left;
    margin-left: 50px;
  }
}

@media only screen and (min-width: 1200px) {
  .archivediv button {
    border: none;
    border-radius: 5px;
    cursor: pointer;
    margin: 10px 35%;
    padding: 0 10px;
    width: 30%;
  }

  .stockdiv button {
    border: none;
    border-radius: 5px;
    cursor: pointer;
    margin: 10px 35%;
    padding: 0 10px;
    width: 30%;
  }

  .followingb {
    width: 20% !important;
    margin: 0 5% 0 75% !important;
    position: absolute;
  }
}

@charset "UTF-8";
/* COLORS */
:root {
  --background_color: #f7f7f7;
  --header_color: #143109;
  --alt_background: #efefef;
  --fun: #ae7f7f;
  --bad: #cd963e;
  --worse: #cd6b3e;
  --worst: #cd3e3e;
  --good: #7acd3e;
  --better: #86ce27;
  --best: #0fdf24;
  --link_color: #aebd93;
  --following_background: #ecf4e4;
  --invert_text: #143109;
  --invert_background: #dcdfd6;
  --selected_link: #7a8450;
  --text_color: #000;
}
body {
  background-color: #f7f7f7;
  background-color: var(--background_color);
}
.footerbody {
  background-color: #efefef;
  background-color: var(--alt_background);
}
.mobilenav {
  background-color: #efefef;
  background-color: var(--alt_background);
  border-color: #143109;
  border-color: var(--header_color);
}
.followingb {
  background-color: transparent;
}
.goodzone {
  color: #7acd3e;
  color: var(--good);
}
.betterzone {
  color: #86ce27;
  color: var(--better);
}
.bestzone {
  color: #0fdf24;
  color: var(--best);
}
.badzone {
  color: #cd963e;
  color: var(--bad);
}
.worsezone {
  color: #cd6b3e;
  color: var(--worse);
}
.worstzone {
  color: #cd3e3e;
  color: var(--worst);
}
.customlogin {
  background-color: transparent;
}
h1,
h2,
h3 {
  color: #143109;
  color: var(--header_color);
}
p,
pre {
  color: #000;
  color: var(--text_color);
}
p::selection {
  background: #efefef;
  background: var(--alt_background);
}
a {
  color: #aebd93;
  color: var(--link_color);
}
a:hover {
  color: #7a8450;
  color: var(--selected_link);
}
u {
  color: #143109;
  color: var(--header_color);
}
button {
  background-color: #aebd93;
  background-color: var(--link_color);
  color: #143109;
  color: var(--invert_text);
}
button:hover {
  background-color: #7a8450;
  background-color: var(--selected_link);
}
form {
  color: #143109;
  color: var(--invert_text);
}
input {
  background-color: #efefef;
  background-color: var(--alt_background);
  color: #143109;
  color: var(--invert_text);
}
input:focus::-webkit-input-placeholder {
  color: transparent;
}
input:focus::placeholder {
  color: transparent;
}
.header {
  background-color: #f7f7f7;
  background-color: var(--background_color);
}
::-webkit-scrollbar-track {
  background-color: #143109;
  background-color: var(--header_color);
}
::-webkit-scrollbar-thumb {
  background: #efefef;
  background: var(--alt_background);
}
.quotesection {
  background-color: #efefef;
  background-color: var(--alt_background);
  border-color: #143109;
  border-color: var(--header_color);
}
.archivediv,
.stockdiv,
.logindiv {
  background-color: #efefef;
  background-color: var(--alt_background);
}
.followingdiv {
  background-color: #ecf4e4;
  background-color: var(--following_background);
}
.selectedlink {
  color: #7a8450;
  color: var(--selected_link);
}
.notselectedlink {
  color: #aebd93;
  color: var(--link_color);
}
.interestingbusiness {
  background-color: #dcdfd6;
  background-color: var(--invert_background);
}
@media only screen and (min-width: 1100px) {
  .rightsection {
    background-color: #efefef;
    background-color: var(--alt_background);
    border-color: #143109;
    border-color: var(--header_color);
  }
}
@media only screen and (min-width: 1200px) {
  .specialrightsection,
  .specialrightsectionforfund {
    background-color: #f7f7f7;
    background-color: var(--background_color);
    border-color: #143109;
    border-color: var(--header_color);
  }
}
/* FONTS */
@font-face {
  font-family: "Pacifico";
  src: local("Pacifico"), url(/static/media/Pacifico-Regular.85bb2d0e.ttf);
  font-weight: normal;
}
@font-face {
  font-family: "Quicksand";
  src: local("Quicksand"), url(/static/media/Quicksand-VariableFont_wght.51b6fe99.ttf);
  font-weight: normal;
}
h1,
h2,
h3 {
  font-family: Pacifico, serif;
  -webkit-font-feature-settings: normal;
          font-feature-settings: normal;
  font-variant: normal;
  font-weight: 400;
  line-height: 50px;
}
h4 {
  font-family: Quicksand, serif;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: 60px;
  text-decoration: none;
}
h1 {
  font-size: 20px;
}
h2 {
  font-size: 15px;
}
h3 {
  font-size: 14px;
}
p,
a {
  font-family: Quicksand, serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 30px;
  text-decoration: none;
}
a {
  text-decoration: underline;
}
u {
  font-family: Quicksand, serif;
  font-size: 15px;
  font-style: normal;
  font-weight: 600;
  line-height: 48px;
  text-decoration: none;
}
button {
  font-family: Quicksand, serif;
  font-size: 14px;
  font-weight: 600;
  line-height: 30px;
  text-decoration: none;
}
form {
  font-family: Quicksand, serif;
  font-size: 14px;
  font-weight: 600;
  line-height: 30px;
  text-decoration: none;
}
input {
  font-family: Quicksand, serif;
  font-size: 14px;
  font-weight: 600;
  line-height: 30px;
  text-decoration: none;
}
.followingp {
  font-family: Pacifico, serif;
  -webkit-font-feature-settings: normal;
          font-feature-settings: normal;
  font-variant: normal;
  font-weight: 400;
  line-height: 30px;
}
.followingb {
  text-decoration: underline;
}
.followingb:hover {
  font-style: italic;
}
@media only screen and (min-width: 700px) {
  h1 {
    font-size: 30px;
    line-height: 80px;
  }
  h2 {
    font-size: 20px;
  }
  h3 {
    font-size: 18px;
  }
  h4 {
    font-size: 20px;
  }
  p,
  a {
    font-size: 17px;
  }
  button {
    font-size: 16px;
  }
  input {
    font-size: 16px;
  }
  u {
    font-size: 18px;
  }
}
@media only screen and (min-width: 1000px) {
  h1 {
    font-size: 40px;
  }
  h2 {
    font-size: 30px;
  }
  h3 {
    font-size: 20px;
  }
}
@media only screen and (min-width: 1100px) {
  h1 {
    font-size: 50px;
  }
  h2 {
    font-size: 40px;
  }
  h3 {
    font-size: 24px;
  }
  h4 {
    font-size: 22px;
  }
}

.header {
  margin: 0;
  padding: 0;
  width: 100%;
}

@media only screen and (min-width: 1000px) {
  .header {
    padding: 0;
    position: fixed;
    left: 60px;
    width: 300px;
  }
  nav {
    display: block;
  }
}
@media only screen and (min-width: 1200px) {
  .header {
    padding: 0;
    position: fixed;
    left: 120px;
    width: 300px;
  }
}
@media only screen and (min-width: 1400px) {
  .header {
    padding: 0;
    position: fixed;
    left: 150px;
    width: 300px;
  }
}

.whywrapper {
  display: flex;
}

.footerbody {
  padding: 20px 0;
}

.googlebuttonimage {
  background-color: transparent !important;
  margin: 0 25%;
  padding: 25px 0;
  width: 50%;
  cursor: pointer;
}

@media only screen and (min-width: 600px) {
  .googlebuttonimage {
    margin: 0 35%;
    padding: 25px 0;
    width: 30%;
  }
}

@media only screen and (min-width: 900px) {
  .mainbody {
    margin: 0;
    padding: 0;
  }
}

@media only screen and (min-width: 1000px) {
  .mainbody {
    margin: 50px 0 0 450px;
    width: 450px;
    padding: 0;
  }

  .footerbody {
    -moz-border-radius: 5px;
    -webkit-border-radius: 5px;
    margin: 0 0 0 450px;
    width: 450px;
  }
}

@media only screen and (min-width: 1100px) {
  .mainbody,
  .footerbody {
    width: 550px;
  }
}

@media only screen and (min-width: 1200px) {
  .mainbody,
  .footerbody {
    width: 600px;
  }
}

@media only screen and (min-width: 1300px) {
  .mainbody,
  .footerbody {
    width: 750px;
    margin: 0 0 0 500px;
  }
}

@media only screen and (min-width: 1400px) {
  .mainbody,
  .footerbody {
    width: 850px;
    margin: 0 0 0 500px;
  }
}

@media only screen and (min-width: 1400px) {
  .mainbody,
  .footerbody {
    width: 850px;
    margin: 0 0 0 500px;
  }
}

@media only screen and (min-width: 1200px) {
  .specialrightsection {
    z-index: 10;
    padding: 8px;
    position: absolute;
    border-radius: 5px;
    left: 800px;
    top: 280px;
    width: 340px;
  }

  .specialrightsectionforfund {
    padding: 8px;
    position: absolute;
    border-radius: 5px;
    left: 800px;
    top: 370px;
    width: 340px;
  }

  .specialrightsection,
  .specialrightsectionforfund {
    border-style: solid;
  }
}

@media only screen and (min-width: 1300px) {
  .specialrightsection,
  .specialrightsectionforfund {
    left: 900px;
  }
}

@media only screen and (min-width: 1400px) {
  .specialrightsection,
  .specialrightsectionforfund {
    left: 950px;
  }
}

.leftsection {
  position: relative;
  z-index: -2;
}
.rightsection,
.anotherrightsection {
  position: relative;
  z-index: 0;
}
.leftsection img {
  border-radius: 50%;
  margin: 0 5%;
  width: 90%;
  user-select: none;
  z-index: -2;
  -moz-user-select: none;
  -ms-user-select: none;
  -webkit-user-drag: none;
  -webkit-user-select: none;
}
.quotesection {
  width: 80%;
  margin: 20px 10%;
  box-sizing: border-box;
  border-radius: 5px;
  border-style: solid;
}

@media screen and (min-width: 360px) {
  .leftsection {
    float: left;
    position: inherit;
    width: 30%;
  }
  .leftsection img {
    -webkit-clip-path: ellipse(200px 200px at -18% 150px);
            clip-path: ellipse(200px 200px at -18% 150px);
    border-radius: 0;
    margin: 0;
    object-fit: scale-down;
    object-position: -130px;
    position: absolute;
    width: 100%;
  }

  .rightsection {
    float: right;
    padding: 50px 0 0 0;
    position: inherit;
    width: 60%;
  }
  .anotherrightsection {
    float: right;
    padding-top: 20px;
    position: inherit;
    width: 100%;
  }
  .rightsection p {
    text-align: left;
  }
}
@media screen and (min-width: 400px) {
  .leftsection img {
    object-position: -150px;
  }
  .rightsection {
    width: 65%;
  }
  .anotherrightsection {
    padding: 50px 0 0 0;
  }
}
@media screen and (min-width: 500px) {
  .leftsection img {
    -webkit-clip-path: ellipse(200px 200px at -5% 150px);
            clip-path: ellipse(200px 200px at -5% 150px);
    object-position: -170px;
  }
  .rightsection {
    width: 67%;
  }
  .anotherrightsection {
    padding: 120px 0 0 0;
  }
}
@media screen and (min-width: 550px) {
  .anotherrightsection {
    padding: 160px 0 0 0;
  }
}
@media screen and (min-width: 600px) {
  .leftsection img {
    -webkit-clip-path: ellipse(200px 200px at 0 150px);
            clip-path: ellipse(200px 200px at 0 150px);
    object-position: -200px -100px;
  }
}
@media screen and (min-width: 700px) {
  .rightsection {
    padding: 75px 0 0 0;
  }
  .leftsection img {
    -webkit-clip-path: ellipse(200px 200px at 5% 160px);
            clip-path: ellipse(200px 200px at 5% 160px);
    object-position: -200px -100px;
  }
}
@media screen and (min-width: 800px) {
  .leftsection img {
    -webkit-clip-path: ellipse(200px 200px at 10% 160px);
            clip-path: ellipse(200px 200px at 10% 160px);
    object-position: -260px -100px;
  }
}
@media only screen and (min-width: 900px) {
  .whywrapper {
    display: flex;
    flex-direction: column;
  }
  .leftsection {
    width: 40%;
  }
  .leftsection img {
    border-radius: 50%;
    float: right;
    -webkit-clip-path: none;
            clip-path: none;
    position: unset;
    object-position: unset;
    width: 90%;
  }
  .rightsection {
    float: right;
    width: 60%;
  }
  .rightsection p {
    padding-left: 20px;
    text-align: left;
  }
  .anotherrightsection {
    padding: 20px 0 0 0;
  }
}
@media only screen and (min-width: 1000px) {
  .leftsection {
    float: none;
    position: relative;
    width: 74%;
  }
  .leftsection img {
    border-radius: 50%;
    float: none;
    -webkit-clip-path: none;
            clip-path: none;
    margin: 0;
    position: relative;
    object-position: 0;
    width: 100%;
  }
  .rightsection {
    float: none;
    padding: 0;
    position: relative;
    width: 100%;
  }
  .rightsection p {
    padding: 0;
  }
  .anotherrightsection {
    padding: 50px 0 0 0;
  }
}
@media only screen and (min-width: 1100px) {
  .leftsection {
    width: 80%;
  }
  .rightsection {
    padding: 8px;
    position: absolute;
    border-radius: 5px;
    left: 700px;
    top: 670px;
    width: 300px;
  }
  .rightsection {
    border-style: solid;
  }
  .anotherrightsection {
    padding: 60px 0 0 0;
  }
}
@media only screen and (min-width: 1300px) {
  .rightsection {
    left: 900px;
  }
  .anotherrightsection {
    padding: 20px 0 0 0;
  }
}
@media only screen and (min-width: 1400px) {
  .rightsection {
    left: 1000px;
  }
}

