@media only screen and (min-width: 1200px) {
  .specialrightsection {
    z-index: 10;
    padding: 8px;
    position: absolute;
    border-radius: 5px;
    left: 800px;
    top: 280px;
    width: 340px;
  }

  .specialrightsectionforfund {
    padding: 8px;
    position: absolute;
    border-radius: 5px;
    left: 800px;
    top: 370px;
    width: 340px;
  }

  .specialrightsection,
  .specialrightsectionforfund {
    border-style: solid;
  }
}

@media only screen and (min-width: 1300px) {
  .specialrightsection,
  .specialrightsectionforfund {
    left: 900px;
  }
}

@media only screen and (min-width: 1400px) {
  .specialrightsection,
  .specialrightsectionforfund {
    left: 950px;
  }
}
