.whywrapper {
  display: flex;
}

.footerbody {
  padding: 20px 0;
}

.googlebuttonimage {
  background-color: transparent !important;
  margin: 0 25%;
  padding: 25px 0;
  width: 50%;
  cursor: pointer;
}

@media only screen and (min-width: 600px) {
  .googlebuttonimage {
    margin: 0 35%;
    padding: 25px 0;
    width: 30%;
  }
}

@media only screen and (min-width: 900px) {
  .mainbody {
    margin: 0;
    padding: 0;
  }
}

@media only screen and (min-width: 1000px) {
  .mainbody {
    margin: 50px 0 0 450px;
    width: 450px;
    padding: 0;
  }

  .footerbody {
    -moz-border-radius: 5px;
    -webkit-border-radius: 5px;
    margin: 0 0 0 450px;
    width: 450px;
  }
}

@media only screen and (min-width: 1100px) {
  .mainbody,
  .footerbody {
    width: 550px;
  }
}

@media only screen and (min-width: 1200px) {
  .mainbody,
  .footerbody {
    width: 600px;
  }
}

@media only screen and (min-width: 1300px) {
  .mainbody,
  .footerbody {
    width: 750px;
    margin: 0 0 0 500px;
  }
}

@media only screen and (min-width: 1400px) {
  .mainbody,
  .footerbody {
    width: 850px;
    margin: 0 0 0 500px;
  }
}

@media only screen and (min-width: 1400px) {
  .mainbody,
  .footerbody {
    width: 850px;
    margin: 0 0 0 500px;
  }
}
